<template>
  <div class="about">
    <div class="section hero-grey">
      <div class="container">
        <div class="small-container center">
          <h2 class="h2 margin">
            <h5 class="h5">
              {{
                institucion.tipo_institucion == "ESFM"
                  ? "Escuela Superior de Formación de Maestras y Maestros"
                  : ""
              }}
            </h5>
            {{ institucion.nombre }}
          </h2>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="breadcrumbs">
          <router-link to="/institucion" class="link breadcrumb">
            Escuelas
          </router-link>
          <div class="slash">/</div>
          <div class="breadcrumb-text">
            detalle
          </div>
        </div>
        <div class="w-dyn-list">
          <div role="listitem" class="w-dyn-item">
            <div class="post-card-v-3"> 
              

              <div class="post-image-container">
                <img
                :src="institucion.imagen ? 'images/' +  institucion.imagen : 'images/esfm.jpeg'"
                  loading="lazy"
                  alt="Logo escuela"
                  sizes="(max-width: 479px) 36vw, (max-width: 991px) 10vw, 5vw"
                  class="post-image-v-2"  
                />

                <!--<img
                  src="images/esfm.jpeg"
                  :src="item.id ? 'images/' +  item.id +'.jpg': 'images/salabiblioteca.jpg'"
                  loading="lazy"
                  alt="Logo escuela"
                  sizes="(max-width: 479px) 36vw, (max-width: 991px) 10vw, 5vw"
                  class="post-image-v-2"
                />-->
                
              </div>
              <div class="post-content-v-2 small-text">
                <div class="small-text">
                  Fecha de creación:
                  {{ institucion.fecha_creacion | formatdate }}
                </div>
                <!-- <h4 class="h4">Reseña Histórica</h4>
                <p class="small-text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Delectus ea corrupti blanditiis nemo provident ipsam eum omnis
                  error. Neque incidunt beatae soluta iste ducimus?
                </p> -->
                <h4 class="h4 space-top-1">Ubicación</h4>
                <p><b>Departamento: </b>{{ institucion.departamento }}</p>
                <p><b>Provincia: </b>{{ institucion.provincia }}</p>
                <p><b>Municipio: </b>{{ institucion.municipio }}</p>
                <p><b>Localidad: </b>{{ institucion.localidad }}</p>
                <p><b>Dirección: </b>{{ institucion.direccion }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <h4 class="h4">Oferta Académica</h4>
        <table class="responstable small-text">
          <thead>
            <tr>
              <th width="">Nro.</th>
              <th>Especilidad</th>
            </tr>
          </thead>
          <tbody v-if="institucion.especialidades.length > 0">
            <tr
              v-for="(item, index) in institucion.especialidades"
              :key="index"
            >
              <td>{{ index + 1 }}</td>
              <td>
                {{ item.descripcion }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="2" class="text-red">
                No existe especialidades asignadas.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="section padding-bottom">
      <div class="container">
        <!-- <h4 class="h4">Personal Directivo</h4> -->
        <div class="w-dyn-list">
          <div role="list" class="posts-grid-v-2 w-dyn-items">
            <div
              role="listitem"
              class="w-dyn-item"
              v-if="institucion.directivo.length > 0"
            >
              <h4 class="h4">Personal Directivo</h4>
              <div
                class="space-top-2 small-text"
                v-for="(personal, index) in institucion.directivo"
                :key="index"
              >
                <p>
                  <b>{{ personal.cargo }}</b>
                </p>
                <span class="icon-arrow-right5 blue"></span>
                {{ personal.nombre }}
              </div>
            </div>
            <div role="listitem" class="w-dyn-item">
              <h4 class="h4">Localización</h4>
              <div id="map" style="width: 400px; height: 300px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Service from "../router/endpoint";
import mapboxgl from "mapbox-gl";
import axios from "axios";
export default {
  name: "institution-detail",
  data() {
    return {
      loading: false,
      institucion_id: "",
      institucion: {
        nombre: "",
        departamento: "",
        provincia: "",
        direccion: "",
        coordenada: "",
        especialidades: [],
        directivo: [],
      },
      map: null,
    };
  },
  mounted() {
    mapboxgl.accessToken = Service.getTokenMap();
    this.institucion_id = Service.getInstitutionId();
    if (this.institucion_id) {
      this.getInstitucion(this.institucion_id);
    } else {
      this.$router.push({ name: "institucion" });
    }
  },
  methods: {
    getInstitucion(institucion_id) {
      this.loading = true;
      axios
        .post(
          Service.getBase() + "institucion/detalle",
          { institucion_id: institucion_id },
          Service.getHeader()
        )
        .then((response) => {
          this.loading = false;
          this.institucion = response.data;
          if (this.institucion.coordenada) {
            let coordenadas = JSON.parse(this.institucion.coordenada);
            this.map = new mapboxgl.Map({
              container: "map",
              //style: "mapbox://styles/mapbox/streets-v11",
              style: "mapbox://styles/dgfm/cl9mzmfnz003915o5bn8ighdf",
              center: [coordenadas[0], coordenadas[1]],
              zoom: 14,
            });
            this.map.addControl(new mapboxgl.NavigationControl());
              new mapboxgl.Marker()
              .setLngLat([coordenadas[0], coordenadas[1]])
              .addTo(this.map);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },
  },
};
</script>
